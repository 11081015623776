.WerkIntro {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding:0 80px;

    .WerkSvgContainer {
            background: url(https://ma-ve.nl/images/sfeer3.jpg);
            height: 340px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;
    }

    .WerkintroText {
          padding:20px 0 0 0 ;        

        h3 {
            text-align:center
        }
    }
}

.heistelling {
    width:200px;
    height: auto;
    display:block;
    margin: 0 auto;
}


@media screen and (max-width:768px){
    .svgIntro {
        flex-direction: column;

        .svgContainer img {
            margin:20px auto;
            display:block;    
        }
    }

    .WerkIntro {
        padding:0px;

        .WerkSvgContainer img {
            width:100%;
        }

        .WerkintroText {
            padding:20px;
        }
    }
}
.footer {
    height:10vh;
    overflow:hidden;
    border-top:1px solid rgb(192, 192, 192);
    display:flex;
    justify-content: center;
    align-items: center;

    h3 {
        font-size:1.2rem;
    }
}
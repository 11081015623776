.landing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0px;
    background: url(https://ma-ve.nl/images/sfeer4.jpg);
    padding: 100px;
    background-repeat: no-repeat;
    background-size: cover;
}


.hero-svg {
    padding: 0px;
    width:60%;
    margin:0 auto;
    display:flex;
    justify-content: space-between;

    .imgLeft {
        width: 20%;
        margin: 0 0 auto auto;
        display: block;
    }

    .imgRight {
        width: 60%;
        margin: auto;
        display: block;
    }
}

.intro {
    width:50%;
    margin:30px;
    padding:20px;
    display:flex;
    flex-direction: column;
    align-items: center;
    background:white;

    &-logo img {
        width:250px;
    }

    h2 {
        margin: 20px 0;
        text-align: center;
    }

    h3 {
       text-align:center;
    }
}


@media screen and (max-width:768px){

    .landing {
        padding:0px;
        background:none;
    }
    .hero-image{
        display:none;
        visibility: hidden;
    }

    .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0 20px;
        width:100%;
        margin:0;
        
        h2 {
            text-align: center;
            margin: 20px 0;
            
        }
    }

    .landing {
        flex-direction:column;
    }

    .container {
        padding-top:0;
    }

    .hero-svg {
        padding: 20px 20px;
        width:100%;
    
        img {
            width:100%;
        }
    }

    .divide {
        background: url(https://ma-ve.nl/images/sfeer4.jpg);
        height: 133px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
    }
    .divide2 {
        background: url(https://ma-ve.nl/images/sfeer3.jpg);
        height: 133px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
    }
}



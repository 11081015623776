

.contactLanding {
    padding: 40px 80px;

    .contactWelcome {
        text-align:left;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 50px 0;

        .welcomeText {
            width:60%;
            align-self:flex-start;
        }

        .socials {
            width:40%;

            img {
                width:40px;
                height: auto;
                margin-left:auto;
                display:block;                
                
            }
        }
    }

    .formContainer {
        margin: 10px 0; 

        form {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            label {
                align-self: flex-start;
            }

            input,
            textarea {
                width:100%;
                padding:10px;
                margin: 0 0 10px 0;
                border:1px solid rgb(196, 196, 196);

                &:focus {
                    outline:1px solid #ED1B24;
                }
            }
            input[type="submit"]{
                width:100%;
                padding:15px;
                background:#ED1B24;
                color:white;
                border:none;
                margin:20px 0;
                transition:background 0.2s ease;

                &:hover {
                    background:#7F7F7F;
                }
            }            
        }
    }
}

.succesMail {
    border: 1px solid #ed1b24;
    width: 100%;
    padding: 10px;
}

.buttoen {
    background:#fff;
}

@media screen and (max-width:768px){
    .contactLanding {
        padding: 20px;
    }
}

.nocaptcha {
    background: #ed1b24;
    width:100%;
    margin:10px;
    text-align: center;
    padding:10px;
    color:white;
}

.capt {
    align-self: flex-start;
    overflow: hidden;
}

.capt div {
    overflow: hidden;
}

.button {
    position: relative;
    height: auto;
    width: 100%;
    background-image: none;
    border: none;
    outline: none;
    background-color: #ed1b24;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    overflow: hidden;
    padding: 10px;
}

.button::after {
	content: '';
	display: block;
	position: absolute;
	width: 160px;
	height: 40px;
	background-color: black;
	z-index: -1;
	top: 10px;
	opacity: 0.3;
	filter: blur(5px);
	transition: all 0.2s ease-out;
}
.button:hover::after {
	opacity: 0.5;
	filter: blur(20px);
	// transform: translatey(10px) scalex(1.2);
}
.button:active {
	background-color: #dd4b4b;
}
.button:active::after {
	opacity: 0.3;
}
.loading {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    overflow: hidden;
}
.button.loading::after {
	width: 40px;
	left: 5px;
	top: 12px;
	border-radius: 100%;
}
.spinner {
	display: block;
	width: 34px;
	height: 34px;
	position: absolute;
	top: 8px;
	left: calc(50% - 17px);
	background: transparent;
	box-sizing: border-box;
	border-top: 4px solid white;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-radius: 100%;
	animation: spin 0.6s ease-out infinite;
}
@keyframes spin {
	100% {transform: rotate(360deg)}
}
* {
  padding:0;
  margin:0;
  box-sizing: border-box;
  overflow-x:hidden;
}

body {
  font-family: 'Heebo', sans-serif;
  overflow-x:hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

h3 {
  font-size:1.6rem;
}

.mw {
  max-width:1200px;
  margin:0 auto;
}

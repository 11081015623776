.svgIntro {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .svgContainer {
        padding:20px;
        flex: 1 1 auto;
        align-self: flex-end;

        img {
            width:100%
        }
    }

    .introText {
        padding: 20px 0; 
        flex:1 1 auto;
        h3 {
            text-align:center
        }
    }
}

.introImage {
    background: url(https://ma-ve.nl/images/sfeer1.jpg);
            height: 340px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;
}


@media screen and (max-width:768px){
    .svgIntro {
        flex-direction: column;
        padding:0px;

        .svgContainer {
            width: 70%;
            margin: auto;
            padding: 0 20px;
        
            img {
                margin:20px auto;
                display:block;
                width:100%;    
            }
        }

        .introText {
            padding: 20px;
            width:100% 
        }
    }
    
}
button {
    width:50%;
    padding:15px;
    background:#ED1B24;
    color:white;
    border:none;
    margin:20px 0;
    transition:background 0.2s ease;

    &:hover {
        background:#7F7F7F;
    }

    &.block {
        margin: 20px auto;
        display:block;
    }

    &.fullWidth {
        width:100%;
    }

    &.nomar {
        margin:0;
    }
}
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height:8vh;
    background-color:white;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;

    .NavLogo img {
        width:100px;
        padding:10px;
    }

    .naviLinks {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;

        div {
            margin-right: 30px;
        }
    }
}

@media screen and (max-width: 1200px) {

    .line {
        width:30px;
        height: 3px;
        background: rgb(58, 58, 58);
        margin:5px;
    }

    nav {
        position: relative;
        height: 10vh;
        background-color: #fff;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .NavLogo img {
        padding:15px !important;
    }

    .hamburger {
        position: absolute;
        cursor: pointer;
        right:5%;
        top:50%;
        transform:translate(-5%, -50%);
        z-index:2;
        overflow: hidden;

        div {
            transition: all 0.3s ease;
        }
    }

    .naviLinks {
        position:fixed;
        background: rgb(199, 199, 199);
        color:rgb(58, 58, 58);
        height:100vh;
        width:100% !important; 
        flex-direction: column;
        justify-content: center !important;
        clip-path: circle(100px at 90% -10%);
        -webkit-clip-path: circle(100px at 90% -10%);
        transition: all 0.35s ease-out;
        pointer-events: none;
        top: -10%;
        left: 0;

        li {
            margin-bottom:30px;

            div {
                color:rgb(58, 58, 58);
                margin-right:0;
            }
        }

    }

    .naviLinks.open{
            clip-path: circle(1200px at 90% -10%);
            -webkit-clip-path: circle(1200px at 90% -10%);
            pointer-events: all;
            top: 0;
            left: 0;
            transition: all 0.35s ease-out;
            z-index: 1;
    }

    .toggle .line1 {
        transform:rotate(-45deg) translate(-5px, 6px)
    }

    .toggle .line2 {
        opacity: 0;
    }

    .toggle .line3 {
        transform:rotate(45deg) translate(-5px, -6px)
    }

}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }


